
  import { mapGetters } from 'vuex';
  import recaptchaImportMixin from '@/plugins/reCaptcha';

  export default {
    inject: ['$validator'],
    props: {
      listId: {
        type: String,
        required: true,
      },
      form: {
        type: Object,
        required: false,
      },
    },
    components: {
      MPowerValidateInput: () => import('../MPowerValidateInput'),
    },
    data() {
      return {
        email: '',
        loading: false,
        isSignupSuccess: false,
        isSignupFail: false,
        error: '',
      };
    },
    mixins: [recaptchaImportMixin],
    computed: {
      ...mapGetters(['storeInformation']),
    },
    methods: {
      async submit() {
        await this.$validator.validateAll();
        if (this.formIsInvalid) return;

        try {
          this.loading = true;
          this.isSignupFail = false;
          const { storeUrl } = this.storeInformation;
          const recaptchaToken = await this.getReCaptchaToken();
          const params = { email: this.email, listId: this.listId, recaptchaToken, storeUrl };
          this.isSignupSuccess = !!(await this.$api.newsletter.newsletterSignup(params));
        } catch (e) {
          this.error = e?.response?.data?.message || '';
          this.isSignupFail = true;
        } finally {
          this.loading = false;
        }
      },
    },
  };
